import { CircularProgress } from '@mui/material';
import React from 'react';
import AppHeader from './Components/AppHeader';
import CreatePage from './Components/CreatePage';

import {League} from './Components/League'
import LeaguePage from './Components/LeaguePage';
import './App.css';
import ManagePage from './Components/ManagePage';

interface AppProps {

}

interface AppState {
  league: League
  route: string
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps){
    super(props)
    this.setState({route: "Loading"})

    console.log(process.env.NODE_ENV)

    fetch(process.env.REACT_APP_OSF_LEAGUE_ENDPOINT as string)
      .then((res)=> {
        if(res.status !== 200){
          console.log("oh bad i guess")
        }else{
          return res.json()
        }
      }).then((data)=> {
        let league = Object.assign(new League(), data)
        league.parseTeams()
        league.parseUnownedPlayers()
        this.setState({
          league: league,
          route: "League"
        })
      })
  }

  headerClicked = (path: string) => {
    console.log(path)
    this.setState({route: path})
  }

  renderPage(){
    switch(this.state?.route){
      case "League":
        return <LeaguePage leagueData={this.state.league} />
      case "Create":
        return <CreatePage />
      case "Manage":
        return <ManagePage leagueData={this.state.league} />
      case "Loading":
      case null:
        return <CircularProgress />
    }
  }


  render() {
    return (
      <div className="App"> 
        <AppHeader cb={this.headerClicked} />
        {this.renderPage()}
      </div>
    );
  }
}

export default App;
