import React from "react";
import { League, Team, Player } from "./League";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PlayerTableRow from "./PlayerTableRow";
import Button from '@mui/material/Button';

interface TeamCardProps{
    leagueData: League
    teamData: Team
    mode: string
    editPlayerCb?: (playerData: Player) => void
    addPlayerCb?: (teamData: Team) => void
}

class TeamCard extends React.Component<TeamCardProps>{
    addPlayer(){
        console.log("add")
        if(this.props.addPlayerCb){
            this.props.addPlayerCb(this.props.teamData)
        }
    }

    render(){
        let rounder = (num: number) => (Math.round(num * 100) / 100).toFixed(2);
        let team = this.props.teamData
        let canAdd = this.props.leagueData.teamSize - this.props.teamData.players.length > 0
        return <div className="Team">
                
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 200 }} aria-label="simple table">
                        <TableHead className="TeamCardHeader">
                            <TableRow>
                                <TableCell>{team.owner}</TableCell>
                                <TableCell align="right">{rounder(team.getTeamTotal())}</TableCell>
                                {this.props.mode === "edit" &&
                                <TableCell>Edit</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {team.players.map((player)=> (
                                <PlayerTableRow
                                    key={player.mlb_id}
                                    playerData={player}
                                    mode={this.props.mode}
                                    editPlayerCb={this.props.editPlayerCb}
                                />
                            ))}
                            {this.props.mode === "edit" && canAdd &&
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Button variant="outlined" sx={{color:"success.main"}} onClick={()=>this.addPlayer()}>
                                            Add a player
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                </div>
    }
}

export default TeamCard