

export interface LeagueData {
    name: string;
    commisioner: string;
    description: string;
    source: string;
    playerPoolFilterType: string;
    playerPoolFilter: string;
    year: number;
    teamSize: number;
    teams: (TeamsEntity)[];
    unownedPlayers: (Player)[];
  }
  export interface TeamsEntity {
    owner: string;
    players: (PlayersEntity)[];
  }
  export interface PlayersEntity {
    name: string;
    mlb_id: number;
    br_id: string;
    playerType: number;
    waviedOn?: null;
    statsAccumulatedWhenWaivered: number;
    preAcquisitionStats: number;
    stat: number;
  }
  
class Player {
    name_common: string;
    mlb_id: number;
    player_ID: string;
    playerType: number;
    waviedOn?: string;
    addedDate?: string;
    statsAccumulatedWhenWaivered: number;
    preAcquisitionStats: number;
    WAR: number;

}
class Team {
    owner: string;
    players: (Player)[];

    parsePlayers(){
        this.players = this.players.map((player: Object)=> {
            let playerObject = Object.assign(new Player(), player)
            return playerObject
        })
    }

    getTeamTotal():number{
        let total = 0.0
        for(let player of this.players){
            if(player.waviedOn){
              total += player.statsAccumulatedWhenWaivered
            }else{
              if(player.WAR){
                total += player.WAR
              }
            }
            if(player.addedDate){
              total -= player.preAcquisitionStats
            }
        }
        return total
    }
}

class League {
    name: string;
    commisioner: string;
    description: string;
    source: string;
    playerPoolFilterType: string;
    playerPoolFilter: string;
    year: number;
    teamSize: number;
    teams: (Team)[];
    unownedPlayers: (Player)[];

    parseTeams(){
        this.teams = this.teams.map((team: Object)=> {
            let teamObject = Object.assign(new Team(), team)
            
            teamObject.parsePlayers()
            return teamObject
        })
    }
    parseUnownedPlayers(){
      this.unownedPlayers = this.unownedPlayers.map((player: Object)=> {
          let playerObject = Object.assign(new Player(), player)
          return playerObject
      })
      this.unownedPlayers.sort((a,b)=> b.WAR - a.WAR)
    }
}

export {League, Team, Player}

/*
{
  "name": "The boys",
  "commisioner": "Chad",
  "description": "Here is where a description of our league would go",
  "source": "baseballreference",
  "playerPoolFilterType": "Team",
  "playerPoolFilter": "SEA",
  "year": 2021,
  "teamSize": 2,
  "teams": [
    {
      "owner": "Erik",
      "players": [
        {
          "name": "Marco Gonzales",
          "mlb_id": 594835,
          "br_id": "gonzama02",
          "playerType": 0,
          "waviedOn": null,
          "statsAccumulatedWhenWaivered": 0,
          "preAcquisitionStats": 0,
          "stat": 2.2
        },
        {
          "name": "Cal Raleigh",
          "mlb_id": 663728,
          "br_id": "raleica01",
          "playerType": 0,
          "waviedOn": null,
          "statsAccumulatedWhenWaivered": 0,
          "preAcquisitionStats": 0,
          "stat": -0.51
        }
      ]
    },
    {
      "owner": "JeDi",
      "players": [
        {
          "name": "Ty France",
          "mlb_id": 664034,
          "br_id": "francty01",
          "playerType": 0,
          "waviedOn": null,
          "statsAccumulatedWhenWaivered": 0,
          "preAcquisitionStats": 0,
          "stat": 4.34
        },
        {
          "name": "JP Crawford",
          "mlb_id": 641487,
          "br_id": "crawfjp01",
          "playerType": 0,
          "waviedOn": null,
          "statsAccumulatedWhenWaivered": 0,
          "preAcquisitionStats": 0,
          "stat": 3.79
        }
      ]
    }
  ]
}
*/