import React from "react";
import {League} from "./League";
import PlayerTableRow from "./PlayerTableRow";
import TeamCard from "./TeamCard";

interface LeaguePageProps{
    leagueData: League
}

class LeaguePage extends React.Component<LeaguePageProps>{
    render(){
        console.log("hi")
        return <div> 
            <div className="leagueInfo">
                {this.props.leagueData.name}
                <br />
                Brought to you by {this.props.leagueData.commisioner}
                <br />
                We are following {this.props.leagueData.playerPoolFilter} in {this.props.leagueData.year}

            </div>
            <div className="Teams-container">
                {this.props.leagueData.teams.map((team)=>
                    <TeamCard teamData={team}  leagueData={this.props.leagueData}  key={team.owner} mode="view" />
                )}
            </div>
            <div id="leagueUnowned">
                Unowned
                {this.props.leagueData.unownedPlayers.map((player) =>
                    
                    <PlayerTableRow
                    key={player.player_ID}
                    playerData={player}
                    mode="view"
                    />
                )}
            </div>
        </div>
    }
}

export default LeaguePage