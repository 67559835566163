import React from "react";
import {League, Team, Player} from "./League";
import TeamCard from "./TeamCard";
import AddPlayerDialog from "./AddPlayerDialog"

interface ManagePageProps{
    leagueData: League
}

interface ManagePageState{
    adding: boolean
    changes: Change[]
}

interface Change {
    playerId: string
    team: string
    type: string
}

class ManagePage extends React.Component<ManagePageProps, ManagePageState>{
    constructor(props: ManagePageProps){
        super(props)
        this.setState({adding: false, changes:[]})
    }
    editPlayer(playerData: Player): void{
        console.log(playerData.name_common)

    }
    addPlayer = (teamData: Team) => {
        this.setState({adding: true})
    }
    render(){
        return <div> 
            <div className="leagueInfo">
                Editing {this.props.leagueData.name}
                <br />
                We are following {this.props.leagueData.playerPoolFilter} in {this.props.leagueData.year}
            </div>
            <TeamCard 
                teamData={this.props.leagueData.teams[0]} 
                leagueData={this.props.leagueData} 
                editPlayerCb={this.editPlayer} 
                addPlayerCb={this.addPlayer}
                mode="edit" />
            <AddPlayerDialog 
                open={this.state?.adding}/>


        </div>
    }
}

export default ManagePage