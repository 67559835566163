import React from "react";
import { Player } from "./League";
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from "@mui/material/Button";
import Popover from '@mui/material/Popover';

interface PlayerRowProps{
    playerData: Player
    mode: string
    editPlayerCb?: Function
}

interface PlayerRowState{
    status: string
    popoverOpen: boolean
    popoverTarget?: HTMLElement
}

class PlayerTableRow extends React.Component<PlayerRowProps, PlayerRowState>{
    constructor(props: PlayerRowProps){
        super(props)
        this.setState({
            status: "normal",
            popoverOpen: false
        })
    }

    buttonCallback() {
        this.props.editPlayerCb?.(this.props.playerData)
        this.setState({status:"removed"})
    }

    handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            popoverOpen: true,
            popoverTarget: event.currentTarget
        })
    }
    
    handlePopoverClose = () =>  {
        this.setState({
            popoverOpen: false,
            popoverTarget: undefined
        })
    }

    render(){
        let player = this.props.playerData
        let war = player.WAR ?? 0
        let popupText = null
        let rowBackgroundColor = "white"
        
        //Edge cases for adding and removing players.  Should eventually be smarter if a player gets picked up multiple times?
        if(player.addedDate) {
            war -= player.preAcquisitionStats
            popupText = "This player was added to the team on " + player.addedDate + ".  They accumulated " + player.preAcquisitionStats + " prior, which is subtracted from their current WAR"
            rowBackgroundColor = "#aef5c8"
        }

        if(player.waviedOn) {
            war = player.statsAccumulatedWhenWaivered
            popupText = "This player was waived on " + player.waviedOn + ".  Their WAR for this team was locked in on that date"
            rowBackgroundColor = "#f5aebb"
        }

        const rowStyle = ((this.state?.status === "removed") ? {bgcolor: "warning.main"} : {bgcolor: rowBackgroundColor})
        const buttonStyle = ((this.state?.status === "removed") ? {color: "success.main"} : {color: "error.main"})
        const open = Boolean(this.state?.popoverOpen && popupText);

        const rounder = (num: number) => (Math.round(num * 100) / 100).toFixed(2);
        const getPlayerUrl = (id: string) => "https://www.baseball-reference.com/players/" + id[0] + "/" + id + ".shtml"

        return <TableRow 
                    sx={rowStyle}
                    onMouseEnter={this.handlePopoverOpen}
                    onMouseLeave={this.handlePopoverClose}>
                    <TableCell component="th" scope="row">
                        {player.player_ID
                            ? <Link href={getPlayerUrl(player.player_ID)} target="_blank" rel="noopener noreferrer">{player.name_common ?? "No stats yet"}</Link>
                            : <div>{player.name_common}</div>
                        }
                    </TableCell>
                    <TableCell align="right">{rounder(war)}</TableCell>
                    {this.props.mode === "edit" &&
                    <TableCell align="right">
                        <Button variant="outlined" sx={buttonStyle} onClick={()=>this.buttonCallback()}>
                            Remove
                        </Button>
                    </TableCell>
                    }
                <Popover id="player-popover"
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={open}
                  anchorEl={this.state?.popoverTarget}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={this.handlePopoverClose}
                >
                  <div>{popupText}</div>
                </Popover>

            </TableRow>
    }
}

export default PlayerTableRow