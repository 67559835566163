import { Dialog, DialogTitle, Button } from "@mui/material";
import React from "react";

export interface AddPlayerDialogProps {
    open: boolean;
    // selectedValue: string;
    // onClose: (value: string) => void;
}
  
class AddPlayerDialog extends React.Component<AddPlayerDialogProps> {
    render(){
        let { open } = this.props;

        let closeDialog = () => {
            open = false
        }

        return (
            <Dialog open={open}>
                <DialogTitle>Adding a player to team Chad</DialogTitle>
                <Button onClick={closeDialog} />
            </Dialog>
        );
        }
}
export default AddPlayerDialog