import React from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
//import MenuIcon from '@mui/icons-material/Menu';

interface AppHeaderProps {
    cb: Function
}

class AppHeader extends React.Component<AppHeaderProps>{

    render(){
        return  <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            > ONE-STAT {process.env.NODE_ENV}
            </IconButton>
            <Button onClick={()=>this.props.cb("League")} component="div" color="inherit" sx={{ flexGrow: 1 }}>
              League
            </Button>
            <Button onClick={()=>this.props.cb("Create")} component="div" color="inherit" sx={{ flexGrow: 1 }}>
              Create
            </Button>
            <Button onClick={()=>this.props.cb("Manage")} component="div" color="inherit" sx={{ flexGrow: 1 }}>
              Manage
            </Button>
            <Button color="inherit">Login</Button>
          </Toolbar>
        </AppBar>
      </Box>
    }
}

export default AppHeader