import { Button, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface CreateProps {

}

interface CreateState {
  type: string
}
class CreatePage extends React.Component <CreateProps, CreateState> {
    constructor(props:{}){
        super(props)
        this.setState({type: "Team"})
    }

    leagueTypeChanged = (event: React.MouseEvent, value: string) => {
        this.setState({type: value})
        console.log(value)
    }

    handleCreateLeague = (event: React.FormEventHandler<HTMLFormElement>) => {
        //event.preventDefault()
        console.log(event)
    }

    render(){
        return (
            <form id="create-page">
                <TextField id="create-name" className="create-page-input-field" label="League Name" variant="filled" />
                <TextField id="create-commisioner" className="create-page-input-field" label="Commish" variant="filled" />
                <TextField id="create-data-source" className="create-page-input-field" label="Data Source" variant="filled" defaultValue="Baseball Reference" InputProps={{readOnly: true}}/>
                <br/>
                <FormControl>
                    <FormLabel id="league-pool-label">League Pool Type</FormLabel>
                    <ToggleButtonGroup className="row-radio-buttons-group" exclusive value={this.state?.type} color="primary" onChange={this.leagueTypeChanged}>
                        <ToggleButton   value="All" >All</ToggleButton>
                        <ToggleButton  disabled value="League">League</ToggleButton>
                        <ToggleButton  disabled value="Division">Division</ToggleButton>
                        <ToggleButton  value="Team">Team</ToggleButton>
                    </ToggleButtonGroup>
                </FormControl>
                <br/>
                {/* {"call function here to return type of data whatever for filter"} */}
                <br/>
                <TextField id="create-year" className="create-page-input-field" label="Year" variant="filled" defaultValue="2021" InputProps={{readOnly: true}}/>
                <TextField id="create-team-number" className="create-page-input-field" label="Number of teams" variant="filled" />
                <TextField id="create-team-size" className="create-page-input-field" label="Players per team" variant="filled" />
                <br />
                <Button type="submit">Create League</Button>
            </form>
        )
    }
}

export default CreatePage

// description: string;
// teamSize: number;
// teams: (TeamsEntity)[];

/*<RadioGroup row name="row-radio-buttons-group"
                >
                    <FormControlLabel disabled value="All" control={<Radio />} label="All" />
                    <FormControlLabel disabled value="League" control={<Radio />} label="League" />
                    <FormControlLabel disabled value="Division" control={<Radio />} label="Division" />
                    <FormControlLabel value="Team" control={<Radio />} label="Team" />
                </RadioGroup>*/